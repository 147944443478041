import moment from 'moment';
import React from 'react';
import {
  Button,
  Grid,
  Image,
  Section,
  ShapeContainer,
  Text,
  TrueBlueRankPanel,
} from '../../components';
import { ROUTE_PATH, redirect } from '../../helpers';
import { ENUM } from '../../helpers/constants/enum';
import { getUserAuth } from '../../helpers/functions/auth';
import { getSeeAnnouncementLocalStorage } from '../../helpers/functions/data';
import { displayWithComma } from '../../helpers/functions/display';
import { getLanguage } from '../../helpers/functions/language';
import {
  CONTENTS,
  ICONS,
  SHAREDS,
  TYPOGRAPHYS,
  VARIABLES,
} from './../../themes';

export class MenuTrueBlue extends React.Component {
  constructor(props) {
    super(props);
    const userAuth = getUserAuth() || {};
    this.state = {
      urlMoreDetail:
        'https://zacsroof.nsbluescope.com/th/%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%9A%E0%B8%A5%E0%B8%B9%E0%B8%AA%E0%B9%82%E0%B8%84%E0%B8%9B-%E0%B9%81%E0%B8%8B%E0%B8%84%E0%B8%AA%E0%B9%8C-zacs/trueblue/',
      userAuth,
    };
  }

  menuTrueBlue = {
    isShowBadgeLuckyDrawAnnouncement: () => {
      const { dataAnnouncement } = this.props;
      let isShow = false;
      const currentTime = new Date();
      if (
        dataAnnouncement &&
        currentTime >= new Date(dataAnnouncement.announcementDate)
      ) {
        const seeAnnouncementData = getSeeAnnouncementLocalStorage();
        if (seeAnnouncementData.id !== dataAnnouncement.id) {
          isShow = true;
        }
      }
      return isShow;
    },
  };


  render() {
    const {
      accumulateDate,
      totalLength,
      totalPurchasing,
      trainedTier,
      onClickExpire
    } = this.props;
    const { urlMoreDetail, userAuth, } = this.state;
    return (
      <Section
        className='is-menu-home'
        paddingHorizontal='20'
        paddingBottom='25'
      >
        <Section spacingBottom='10'>
          <TrueBlueRankPanel
            rankGold={trainedTier === ENUM.CUSTOMER_TRAINED_TIER.GOLD.TEXT}
            rankSilver={trainedTier === ENUM.CUSTOMER_TRAINED_TIER.SILVER.TEXT}
            rankBronze={trainedTier === ENUM.CUSTOMER_TRAINED_TIER.BRONZE.TEXT}
            length={displayWithComma(totalLength)}
            duration={`${moment(accumulateDate.startDate).format(
              'MMM YYYY'
            )} - ${moment(accumulateDate.endDate).format('MMM YYYY')}`}
            totalPurchasing={displayWithComma(totalPurchasing)}
          />
        </Section>
        <Section spacingBottom='10' relative>
          <Button
            fluid
            paddingBottomPercent='28'
            className={'is-banner-lucky-draw'}
            bgSize='cover'
            bgRepeat='no-repeat'
            bgPosition='center'
            borderRadius='10'
            bgImage={SHAREDS['banner-lucky-draw.jpg']}
            onClick={() => {
              redirect(ROUTE_PATH.WIN_PRIZES.LINK);
            }}
          >

            {/* <Section
                align='center'
                height='100'
                heightUnit='%'
                paddingHorizontal='20'
                borderRadius='10'
              >
                <Grid justify='space-between' align='center' gutterVertical='5'>
                  <Grid.Column>
                    <Section spacingTop='15'>
                      <Text
                        className='is-menu-lucky-draw'
                        fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_24}
                        color={VARIABLES.COLORS.WHITE}
                        top='-30'
                      >
                        {getLanguage('home.luckyDraw')}
                      </Text>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <Section relative bottom='-30'>
                      <Button
                        height='18'
                        paddingHorizontal='8'
                        bgColor={VARIABLES.COLORS.WHITE}
                        borderRadius='9'
                        onClick={() => { }}
                      >
                        <Text
                          top='1'
                          fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_10}
                          color={VARIABLES.COLORS.BLUE_2}
                        >
                          {getLanguage('home.moreDetailExtra')}
                        </Text>
                      </Button>
                    </Section>
                  </Grid.Column>
                </Grid>
              </Section> */}
          </Button>
          {this.menuTrueBlue.isShowBadgeLuckyDrawAnnouncement() && (
            <Section absolute justify='flex-end' top='8' right='8'>
              <ShapeContainer
                width='12'
                height='12'
                borderRadius='6'
                bgColor={VARIABLES.COLORS.RED_1}
              />
            </Section>
          )}
        </Section>
        <Section spacingBottom='10'>
          <Grid gutter='10'>
            <Grid.Column grid={4}>
              <Button
                fluid
                shadow
                className='is-menu-bg-logo'
                height='96'
                bgColor={VARIABLES.COLORS.GREEN_4}
                borderRadius={10}
                onClick={() => {
                  redirect(ROUTE_PATH.LOYALTY_POINT.LINK);
                }}
              >
                <Section justify='center'>
                  <Image
                    fluid
                    widthRatio='28'
                    widthRatioUnit='px'
                    heightRatio='36'
                    heightRatioUnit='px'
                    src={ICONS['ic-medal.svg']}
                  />
                </Section>
                <Section justify='center' paddingTop='10'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_11}
                    color={VARIABLES.COLORS.WHITE}
                    align='center'
                  >
                    {getLanguage('home.tbPoints')}
                  </Text>
                </Section>
              </Button>
            </Grid.Column>
            <Grid.Column grid={4}>
              <Button
                fluid
                shadow
                className='is-menu-bg-logo'
                height='96'
                bgColor={VARIABLES.COLORS.PURPLE_1}
                borderRadius={10}
                onClick={() => {
                  redirect(ROUTE_PATH.REWARD.LINK);
                }}
              >
                <Section justify='center'>
                  <Image
                    fluid
                    widthRatio='31'
                    widthRatioUnit='px'
                    heightRatio='36'
                    heightRatioUnit='px'
                    src={ICONS['ic-gift-white.svg']}
                  />
                </Section>
                <Section justify='center' paddingTop='10'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_11}
                    color={VARIABLES.COLORS.WHITE}
                    align='center'
                  >
                    {getLanguage('home.tbRewards')}
                  </Text>
                </Section>
              </Button>
            </Grid.Column>
            <Grid.Column grid={4}>
              <Button
                fluid
                shadow
                className='is-menu-bg-logo'
                height='96'
                bgColor={VARIABLES.COLORS.BLUE_9}
                borderRadius={10}
                onClick={() => {
                  redirect(ROUTE_PATH.PRODUCT.LINK);
                }}
              >
                <Section justify='center'>
                  <Image
                    fluid
                    widthRatio='36'
                    widthRatioUnit='px'
                    heightRatio='36'
                    heightRatioUnit='px'
                    src={ICONS['ic-shopping-bag-white.svg']}
                  />
                </Section>
                <Section justify='center' paddingTop='10'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_11}
                    color={VARIABLES.COLORS.WHITE}
                    align='center'
                  >
                    {getLanguage('home.products')}
                  </Text>
                </Section>
              </Button>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='10'>
          <Grid gutter='10'>
            <Grid.Column grid={6}>
              <Button
                fluid
                className='is-column'
                height='96'
                bgImage={CONTENTS['home-menu-2.jpg']}
                bgSize='cover'
                bgRepeat='no-repeat'
                bgPosition='center'
                borderRadius='10'
                onClick={() => {
                  redirect(`${ROUTE_PATH.MY_PROJECTS.LINK}/${userAuth.id}`);
                }}
              >
                <Section
                  height='100'
                  heightUnit='%'
                  bgColor={'rgba(48, 131, 187, 0.75)'}
                  borderRadius='10'
                  paddingTop='20'
                >
                  <Section justify='center'>
                    <Image
                      fluid
                      widthRatio='45'
                      widthRatioUnit='px'
                      heightRatio='43'
                      heightRatioUnit='px'
                      src={ICONS['ic-contract.svg']}
                    />
                  </Section>
                  <Section justify='center' paddingTop='10'>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {getLanguage('home.myProjects')}
                    </Text>
                  </Section>
                </Section>
              </Button>
            </Grid.Column>
            <Grid.Column grid={6}>
              <Button
                fluid
                className='is-column'
                height='96'
                bgImage={CONTENTS['home-menu-5.jpg']}
                bgSize='cover'
                bgRepeat='no-repeat'
                bgPosition='center'
                borderRadius='10'
                onClick={() => {
                  redirect(ROUTE_PATH.KNOWLEDGE_BASE.LINK);
                }}
              >
                <Section
                  height='100'
                  heightUnit='%'
                  bgColor={'rgba(4, 70, 113, 0.75)'}
                  borderRadius='10'
                  paddingTop='20'
                >
                  <Section justify='center'>
                    <Image
                      fluid
                      widthRatio='45'
                      widthRatioUnit='px'
                      heightRatio='43'
                      heightRatioUnit='px'
                      src={ICONS['ic-book.svg']}
                    />
                  </Section>
                  <Section justify='center' paddingTop='10'>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {getLanguage('home.knowledgeBase')}
                    </Text>
                  </Section>
                </Section>
              </Button>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='10'>
          <Grid gutter='10'>
            <Grid.Column grid={6}>
              <Button
                fluid
                className='is-column'
                height='96'
                bgImage={CONTENTS['home-menu-6.jpg']}
                bgSize='cover'
                bgRepeat='no-repeat'
                bgPosition='center'
                borderRadius='10'
                onClick={() => {
                  redirect(ROUTE_PATH.PURCHASE_HISTORY.LINK);
                }}
              >
                <Section
                  relative
                  height='100'
                  heightUnit='%'
                  bgColor={'rgba(4, 70, 113, 0.75)'}
                  borderRadius='10'
                  paddingTop='20'
                >
                  <Section justify='center'>
                    <Image
                      fluid
                      widthRatio='45'
                      widthRatioUnit='px'
                      heightRatio='43'
                      heightRatioUnit='px'
                      src={ICONS['ic-bill.svg']}
                    />
                  </Section>
                  <Section justify='center' paddingTop='10'>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {getLanguage('home.purchaseHistory')}
                    </Text>
                  </Section>
                </Section>
              </Button>
            </Grid.Column>
            <Grid.Column grid={6}>
              <Button
                fluid
                className='is-column'
                height='96'
                bgImage={CONTENTS['home-menu-8.jpg']}
                bgSize='cover'
                bgRepeat='no-repeat'
                bgPosition='center'
                borderRadius='10'
                onClick={() => {
                  redirect(ROUTE_PATH.WARRANTY.LINK);
                }}
              >
                <Section
                  height='100'
                  heightUnit='%'
                  bgColor={'rgba(4, 70, 113, 0.75)'}
                  borderRadius='10'
                  paddingTop='20'
                >
                  <Section justify='center'>
                    <Image
                      fluid
                      top='3'
                      left='-3'
                      widthRatio='39'
                      widthRatioUnit='px'
                      heightRatio='43'
                      heightRatioUnit='px'
                      src={ICONS['ic-warranty.svg']}
                    />
                  </Section>
                  <Section justify='center' paddingTop='10'>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {getLanguage('home.warranty')}
                    </Text>
                  </Section>
                </Section>
              </Button>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='10'>
          <Grid gutter='10'>
            <Grid.Column grid={6}>
              <Button
                fluid
                className='is-column'
                height='96'
                bgImage={CONTENTS['home-menu-1.jpg']}
                bgSize='cover'
                bgRepeat='no-repeat'
                bgPosition='center'
                borderRadius='5'
                onClick={() => {
                  redirect(ROUTE_PATH.NEWS_PROMOTIONS.LINK);
                }}
              >
                <Section
                  height='100'
                  heightUnit='%'
                  bgColor={'rgba(4, 70, 113, 0.75)'}
                  borderRadius='5'
                  paddingTop='25'
                >
                  <Section justify='center'>
                    <Image
                      fluid
                      widthRatio='40'
                      widthRatioUnit='px'
                      heightRatio='36'
                      heightRatioUnit='px'
                      src={ICONS['ic-news.svg']}
                    />
                  </Section>
                  <Section justify='center' paddingTop='10'>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {getLanguage('home.newsAndPromotions')}
                    </Text>
                  </Section>
                </Section>
              </Button>
            </Grid.Column>
            <Grid.Column grid={6}>
              <Button
                fluid
                className='is-column'
                height='96'
                bgImage={CONTENTS['user-check-point.png']}
                bgSize='cover'
                bgRepeat='no-repeat'
                bgPosition='center'
                borderRadius='5'
                onClick={onClickExpire}
              >
                <Section
                  height='100'
                  heightUnit='%'
                  bgColor={'rgba(4, 70, 113, 0.75)'}
                  borderRadius='5'
                  paddingTop='25'
                >
                  <Section justify='center'>
                    <Image
                      fluid
                      widthRatio='24'
                      widthRatioUnit='px'
                      heightRatio='31'
                      heightRatioUnit='px'
                      src={ICONS['ic-check-point.svg']}
                    />
                  </Section>
                  <Section justify='center' paddingTop='10'>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {getLanguage('home.checkPoint')}
                    </Text>
                  </Section>
                </Section>
              </Button>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='10'>
          <Button
            fluid
            height='96'
            bgImage={SHAREDS['bg-rank-information.png']}
            bgSize='cover'
            bgRepeat='no-repeat'
            bgPosition='center'
            borderRadius='10'
            onClick={() => {
              window.open(urlMoreDetail, '_self');
            }}
          >
            <Section
              align='center'
              height='100'
              heightUnit='%'
              paddingHorizontal='20'
              // bgColor={'rgba(4, 70, 113, 0.75)'}
              borderRadius='10'
            >
              <Grid justify='space-between' align='center' gutterVertical='5'>
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_20}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('home.tbMember')}
                  </Text>
                  <br />
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('home.rankInformation')}
                  </Text>
                </Grid.Column>
                <Grid.Column>
                  <Button
                    height='18'
                    paddingHorizontal='20'
                    bgColor={VARIABLES.COLORS.WHITE}
                    borderRadius='9'
                    onClick={(ev) => {
                      ev.stopPropagation()
                      window.open(urlMoreDetail, '_self');
                    }}
                  >
                    <Text
                      top='1'
                      fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_10}
                      color={VARIABLES.COLORS.BLUE_2}
                    >
                      {getLanguage('home.moreDetail')}
                    </Text>
                  </Button>
                </Grid.Column>
              </Grid>
            </Section>
          </Button>
        </Section>

      </Section>
    );
  }
}
